import { SportField, SportType } from 'modules/sport/types'
import { footballPositionPlacements } from 'modules/team-player/constants/footballPositionPlacements'
import { volleyballPositionPlacements } from 'modules/team-player/constants/volleyballPositionPlacements'

export const fields: Record<SportType, SportField> = {
  Football: {
    icon: 'field-football',
    height: 404.923,
    placements: footballPositionPlacements,
  },
  Volleyball: {
    icon: 'field-volleyball',
    height: 404.923,
    placements: volleyballPositionPlacements,
  },
  Basketball: {
    icon: 'field-football',
    height: 404.923,
    placements: footballPositionPlacements,
  },
  'Ice-Hockey': {
    icon: 'field-football',
    height: 404.923,
    placements: footballPositionPlacements,
  },
  'Field-Hockey': {
    icon: 'field-football',
    height: 404.923,
    placements: footballPositionPlacements,
  },
  Futsal: {
    icon: 'field-football',
    height: 404.923,
    placements: footballPositionPlacements,
  },
  'American Football': {
    icon: 'field-football',
    height: 404.923,
    placements: footballPositionPlacements,
  },
}
