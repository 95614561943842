import { footballPresetTags } from 'modules/asset/constants/presetTags/football'
import { volleyballPresetTags } from 'modules/asset/constants/presetTags/volleyball'
import { PresetTagGroup } from 'modules/asset/types'
import { SportType } from 'modules/sport/types'

export const presetTags: Record<SportType, PresetTagGroup[]> = {
  Football: footballPresetTags,
  Volleyball: volleyballPresetTags,
  Basketball: [],
  Futsal: [],
  'Ice-Hockey': [],
  'Field-Hockey': [],
  'American Football': [],
}
