import { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { StyledTriangleShape, StyledVideo, StyledWrapper } from '.'
import { useVideoPlayerControls } from 'modules/video-player/hooks'
import { VIDEO_SRC } from 'modules/landing/constants'
import { VideoStyledButton } from '../../shared'

export const VideoSection = () => {
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null
  )
  const { play, status } = useVideoPlayerControls(videoElement)

  useEffect(() => {
    const videoNode = document.querySelector('video')
    setVideoElement(videoNode)
  }, [])

  return (
    <StyledWrapper>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, repeat: 0 }}
        >
          <StyledVideo
            isVisible={status === 'play'}
            as={motion.video}
            controls={true}
            src={VIDEO_SRC}
            onClick={() => play()}
          />
        </motion.div>
      </AnimatePresence>

      {status !== 'play' && (
        <VideoStyledButton onClick={play}>
          <StyledTriangleShape />
        </VideoStyledButton>
      )}
    </StyledWrapper>
  )
}
