// Images
import FootballField from 'assets/images/football-field.png'
import VolleyballCourt from 'assets/images/volleyball-court.png'
// Types
import { SportType } from 'modules/sport/types'

export const MINIMAP_SPORT_FIELD: Record<SportType, string> = {
  Football: FootballField,
  Volleyball: VolleyballCourt,
  Basketball: FootballField,
  'Ice-Hockey': FootballField,
  'Field-Hockey': FootballField,
  Futsal: FootballField,
  'American Football': FootballField,
}
